.hoverable-atom {
  position: relative; /* This will help in positioning the icon. */
  transition: all 0.3s;
}
.hoverable-atom .icon-styles {
  border: 2px solid transparent;
}

.hoverable-atom .icon-styles:hover {
  background-color: white !important;
  border: 2px solid #e5b04f;
}
.hoverable-atom .hover-icon {
  position: absolute;
  bottom: 10px; /* Adjust as needed */
  right: 10px; /* Adjust as needed */
  opacity: 0;
  transition: opacity 0.3s;
}

.hoverable-atom:hover .hover-icon {
  opacity: 1;
}
